import React from 'react'
import loadable from '@loadable/component'

import Link, { Theme as LinkTheme } from 'components/base/Link'

import {
  events,
  useGoogleTagManager,
} from 'hooks/useGoogleTagManager'

import {
  Color,
  Props,
  Size,
} from './types'

import {
  AskQuestionWrap,
  AskQuestionContent,
  QuestionText,
  QuestionTitle,
} from './styles'

const ConciergeIcon = loadable(() => import('../ConciergeIcon').catch(() => () => null))

const DefaultButton = () => {
  const { pushDataLayerEvent } = useGoogleTagManager()

  return (
    <Link
      theme={LinkTheme.button}
      to='/support/ask-a-question/'
      onClick={() => pushDataLayerEvent({ ...events.cta_ask_question })}
    >
      Ask a Question
    </Link>
  )
}

const AskQuestion: React.FC<Props> = ({
  buttons = <DefaultButton />,
  children,
  color = Color.regular,
  size = Size.regular,
  speech = 'Hi!',
  title,
}): React.ReactElement => (
  <AskQuestionWrap>
    <AskQuestionContent>
      {(title && <QuestionTitle color={color} size={size}>{title}</QuestionTitle>) || undefined}
      <ConciergeIcon title={speech} />
      <QuestionText size={size}>{children}</QuestionText>
      {buttons}
    </AskQuestionContent>
  </AskQuestionWrap>
)

export * from './types'

export default AskQuestion
